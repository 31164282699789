import { useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineCall } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
// import { BsCart3 } from "react-icons/bs";
// import { FiMenu } from "react-icons/fi";

import { IoIosClose } from "react-icons/io";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import Search from "../components/Search";
import { useEffect } from "react";
import axios from "axios";
import { currencyFormatter } from "../utils/currencyFormatter";
import { BsCart3 } from "react-icons/bs";
import { FaRegUserCircle } from "react-icons/fa";

const Navbar = ({ handleMenu }) => {
  const [setSearchItem] = useState("");
  // const [toggle, setToggle] = useState(false);
  const inputField = useRef(null);
  const { items: data } = useSelector((state) => state.category);
  const { cartItems: product } = useSelector((state) => state.cart);
  const { items: logo } = useSelector((state) => state.logo);

  const navActive = ({ isActive }) => {
    return {
      color: isActive ? "black" : null,
      Font: isActive ? "bold" : null,
    };
  };
  const Navigate = useNavigate();

  const [searchData, setSearchData] = useState([]);
  const [word, setWord] = useState("");
  const [filterData, setFilterData] = useState([]);

  console.log(word);
  useEffect(() => {
    const fetchSearchData = async () => {
      const res = await axios.get(`${process.env.REACT_APP_URL}/api-products`);
      return setSearchData(res.data);
    };
    fetchSearchData();
  }, []);

  const handleChange = (e) => {
    const searchWord = e.target.value;
    setWord(() => searchWord);
    const newFilterData = searchData.filter((value) =>
      value.name.toLowerCase().includes(searchWord.toLowerCase())
    );

    if (searchWord === "") {
      setFilterData([]);
    } else {
      setFilterData(newFilterData);
    }
  };

  const handleClose3 = () => {
    setWord();
    setFilterData([]);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    Navigate("/shop");

    //form reset
    setSearchItem("");
    inputField.current.blur();
  };

  // const search = (e) => {
  //   Navigate("/shop");
  //   setSearchItem(e.target.value);
  // };

  // const handleMenu = () => {
  //   setToggle((prev) => !prev);
  // };
  // const handleClose = () => {
  //   setToggle((prev) => !prev);
  // };
  const { userAndToken: userData } = useSelector((state) => state.auth);
  return (
    <>
      <div className="sticky  md:px-0 -top-[70px] md:top-0 md:left-0 shadow-md  md:right-0 z-[9999] bg-white">
        {/* middle nav */}
        <div className="bg-black pt-5 md:py-5">
          <div className="container mx-auto flex items-center justify-center md:justify-between">
            <Link
              to={"/"}
              className="brand  h-[3rem]  rounded-md overflow-hidden"
            >
              <img
                src={`${process.env.REACT_APP_URL}/backend/assets/jpg/${logo?.avatar}`}
                className="w-full h-full object-cover"
                alt=""
              />
            </Link>
            {/* search button */}
            <div className="hidden lg:block">
              <form
                onSubmit={handleSearch}
                className=" flex items-center justify-center"
              >
                <Search />
              </form>
            </div>

            <div className="hidden md:block">
              <div className=" text-slate-400  text-[0.9rem] text-white flex items-center gap-5 duration-1000">
                <div className="flex gap-1 items-center">
                  <MdOutlineCall />
                  <p>
                    <a href="tel://+8801878104656">+8801878-104656</a>
                  </p>
                </div>
                {!userData && (
                  <>
                    <Link
                      to={`/login`}
                      className=" hover:underline  underline-offset-2 duration-300 "
                    >
                      <FaRegUserCircle className="text-2xl text-[#fff]" />
                    </Link>
                    {/* <span className="text-slate-400/40">or</span>
                  <Link
                    to={`/register`}
                    className="hover:underline underline-offset-2 duration-300"
                  >
                    Registration
                  </Link> */}
                  </>
                )}

                {userData && (
                  <Link
                    to={`/user`}
                    className="flex items-center  justify-center gap-2"
                  >
                    {!userData?.user?.avatar && (
                      <span className=" w-10 h-10 overflow-hidden rounded-full border-2 border-[#fff]">
                        {/* <FaRegUserCircle className="text-2xl" /> */}
                        <img
                          src="/default_image.jpeg"
                          alt="default avatar"
                          className="w-full h-full object-cover"
                        />
                      </span>
                    )}
                    {userData?.user?.avatar && (
                      <span className=" w-12 h-12  overflow-hidden rounded-full border-2 border-[#fff]">
                        {/* <FaRegUserCircle className="text-2xl" /> */}
                        <img
                          src={`${process.env.REACT_APP_URL}/uploads/users/${userData?.user?.avatar}`}
                          alt={`${userData?.user?.name}`}
                          className="w-full h-full object-cover"
                        />
                      </span>
                    )}
                    <p className="text-[0.7rem] capitalize md:block hidden  md:text-[1.1rem]">
                      {userData?.user?.name}
                    </p>
                  </Link>
                )}
                <Link
                  to="/addtocart"
                  className="hidden md:block  relative group"
                >
                  <BsCart3 className="text-2xl text-[#fff]" />
                  <span className=" absolute bottom-3 text-sm left-4 bg-rose-500 h-5 w-5 flex items-center justify-center rounded-full text-white group-hover:bg-sky-500 duration-300">
                    {product.length}
                  </span>
                </Link>
                {/* <Link to="/addtocart" className="relative group">
                  <BsCart3 />
                  <span className=" absolute bottom-[10px]  text-sm left-2 text-white bg-red-500 h-5 w-5 flex items-center justify-center rounded-full group-hover:bg-sky-500 duration-300">
                    {product.length}
                  </span>
                </Link> */}
              </div>
            </div>
          </div>

          {/* search button */}
          <div className="block md:hidden ">
            <form
              onSubmit={handleSearch}
              className="relative flex flex-col items-center justify-center"
            >
              <input
                ref={inputField}
                type="text"
                placeholder="Search Items......"
                onChange={handleChange}
                className=" bg-white border p-3 w-full border-red-500   rounded-md focus:outline-none"
              />
              {filterData.length === 0 ? (
                <FiSearch className="absolute right-3 text-xl text-gray-500" />
              ) : (
                <IoIosClose
                  onClick={handleClose3}
                  className="absolute right-3  text-2xl text-gray-500"
                />
              )}
              {filterData.length !== 0 && (
                <div className="search-content flex flex-col w-full z-[9999] gap-3 bg-white h-auto  p-2 overflow-hidden overflow-y-scroll">
                  {filterData.slice(0, 5).map((product) => (
                    <Link
                      onClick={handleClose3}
                      key={product.id}
                      to={`/productdetails/${product.id}`}
                      className="product-card flex items-center    gap-2"
                    >
                      <div className="product-image w-20 h-20 overflow-hidden">
                        <img
                          src={`${process.env.REACT_APP_URL}/uploads/product/${product.photos}`}
                          alt=""
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <div className="product-content text-black w-full text-sm overflow-hidden">
                        <h4 className="font-semibold">{product?.brand_name}</h4>
                        <p className="truncate text-lg ">{product?.name}</p>
                        <span className="text-gray-400">
                          {currencyFormatter(product?.unit_price)}
                        </span>
                      </div>
                    </Link>
                  ))}
                </div>
              )}
            </form>
          </div>
        </div>

        <div className={` bg-white hidden md:block text-black`}>
          <div className="container mx-auto">
            <div className="flex justify-center">
              <ul className="flex text-[0.9rem] text-violet-50  lg:text-[1rem] gap-5 py-2 justify-between items-center">
                <li>
                  <NavLink
                    style={navActive}
                    end
                    to={"/"}
                    className="hover:text-gray-300 duration-300"
                  >
                    Home
                  </NavLink>
                </li>
                {data?.slice(0, 6).map((navItem) => (
                  <li key={navItem.id}>
                    <NavLink
                      className="hover:text-gray-300 duration-300"
                      style={navActive}
                      to={`/categorypage/${navItem.id}`}
                      key={navItem.id}
                    >
                      {navItem.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
